@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --margin: 0;
  --fontSize: calc(((100vw * 0.783) / 19.2) * 16 / 100);
  --promoHeight: 16rem;
  --promoWidth: 16rem;
  --topnavWidth: 100%;
  --mainWidth: 70vw;
  --footerWidth: 100%;
}

* {
margin:0;
padding:0;
box-sizing:border-box;
font-family: "canada-type-gibson";

}
h1, h2, h3, h4, h5, h6{
  font-size: revert;
  font-weight: revert;
}

html{

    font-family:'canada-type-gibson', 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';


    font-size: 78.6%;


  border-radius: 0.1335rem;
}


html, body, .MuiTypography-root, .MuiButton-root, .MuiInputLabel-root, .MuiInputBase-input {
  font-family: "canada-type-gibson" !important;
}



@media (min-width: 1921px) {
  html {
    font-size: var(--fontSize);
  }
}


button[aria-label="Hoje"]{
    display: none;
}

.high-z-index-snackbar {
  z-index: 2000 !important; /* Adjust the number to be higher than the drawer's z-index */
}

* {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* For Firefox, scrollbar thumb and track colors */
}

/* For Chrome, Safari, and Opera */
*::-webkit-scrollbar {
  width: 8px; /* Vertical scrollbar width */
  height: 8px; /* Horizontal scrollbar height */
}

*::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

*::-webkit-scrollbar-thumb {
  background-color: #888; /* Thumb color */
  border-radius: 10px;
  border: 2px solid #f1f1f1; /* Creates padding around thumb */
}


.chart .donut-segment {
  stroke: #ffffffff;
  stroke-dasharray: calc(var(--percentage)), calc(100 - var(--percentage));
  stroke-dashoffset: 25; /* % do circulo */
}

.chart .background-ring {
  stroke: var(--ring-color);
  stroke-dasharray: calc(100 - var(--percentage)), calc(var(--percentage));
  stroke-dashoffset: calc(25 - var(--percentage));
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px black inset !important; /* Change 'black' to match your input background */
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
}

/* In your global CSS file, e.g., index.css */
input:focus,
textarea:focus,
select:focus,
button:focus {
    outline: none !important;
    box-shadow: none !important;

    --tw-ring-color: transparent !important; /* Make sure the ring color is transparent */
}



/* Calendar.css */
.fc-toolbar.fc-header-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fc .fc-toolbar-title {
  font-size: 1.25em;
}

.fc-button-group .fc-button {
  padding: 0.5em 0.8em;
  font-size: 0.85em;
}

@media (max-width: 768px) {
  .fc-toolbar.fc-header-toolbar {
    flex-direction: row;
    flex-wrap: wrap;
    gap:1rem;
  }

  .fc-toolbar .fc-left, .fc-toolbar .fc-center, .fc-toolbar .fc-right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .fc-toolbar .fc-center {
    justify-content: center;
  }

  .fc-button-group .fc-button {
    padding: 0.4em 0.5em;
    font-size: 0.75em;
    min-width: 45px;
  }

  .fc-button {
    margin-bottom: 5px;
  }

  .fc-col-header-cell {

    word-break: break-word;
    font-size: 0.8em !important;

  }
  .fc-event-title, .fc-event-time {
    white-space: nowrap !important;

  }
}





.animated-marker > div > svg {
  filter: drop-shadow(5px 5px 5px black);
}

.tab-button.active {
  background-color: white;
  color: black;
  font-weight: bold;
  padding-top: 1.75rem;  /* Equivalent to py-7 in Tailwind */
  padding-bottom: 1.75rem; /* Equivalent to py-7 in Tailwind */
}


.fc-col-header-cell {
  width: 10vw;  /* Dynamic width */
  min-width: 8rem;
  max-width: 15rem;
  text-align: center;

  overflow: hidden;  /* Hide overflow */
  text-overflow: ellipsis;  /* Show ellipsis if text overflows */
}

.fc-daygrid{
  overflow-x: auto !important;
}

.fc-event-title, .fc-event-time {
  white-space: normal ;  /* Allow text to wrap */

}

.fc-toolbar-title, .fc-col-header-cell {
  text-transform: capitalize;

}




.chart {
  width: 900px;
  height: 500px;
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* Container (card) styling */
.card-body {
  overflow: hidden; /* Hide any overflow from the canvas */
  background-color: #fff;
}

/* Canvas styling to make it fill the card */
.card-body canvas {
  /* background-color: #DDD; */
  width: 100%; /* Take full width of the card */
  height: 100%; /* Take full height of the card */
  display: block; /* Remove inline spacing */
}

.details input {
  width: 100%;
}

.detail {
  display: flex;
  justify-content: space-between;
}

